import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql, navigate } from 'gatsby'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import BrandGroup from '~components/BrandGroup'
import { useQueryParam, StringParam } from "use-query-params"
import { useLocation } from '@reach/router'
import Seo from '~components/Seo'
import { Filter } from '~components/Svg'
import Button from '~components/Button'
import AZNavMobile from '~components/AZNavMobile'

const AZ = ({ className, data }) => {
	const letters = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","#"]

	const { brands } = data.allSanityBrand

	// make the sort case insensitive
	brands.sort((a, b) => a.title.localeCompare(b.title))

	const [goToLetter, setGoToLetter] = useQueryParam('goTo', StringParam)
	const [currentLetter, setCurrentLetter] = useState()

	const loc = useLocation()

	useEffect(() => {
		if(loc.hash){
			const el = document.querySelector(loc.hash)
			el.scrollIntoView()
			// console.log(window.location)
			// window.location.hash = loc.hash
		}
	}, [])

	useEffect(() => {

		const processScroll = () => {
			if(window.pageYOffset < 20){
				setCurrentLetter('A')
			}
		}

		window.addEventListener('scroll', () => processScroll())
		return () => window.removeEventListener('scroll', () => processScroll())
	}, [])

	return (
		<Wrap className={className}>
			<Seo title="Brands A–Z" />
			<Global styles={css`
				html, body {
					scroll-padding-top: 50px;
					scroll-behavior: smooth;
				}
			`} />
			<Header>
				<h2>Brands A–Z</h2>
				{/* <RefineButtonWrap>
					<RefineButton onClick={() => navigate('/search?open=filter')}>
						<Filter/>
						<RefineButtonText>
						Refine
						</RefineButtonText>
					</RefineButton>
				</RefineButtonWrap> */}
			</Header>
			<NavWrap>
				<Nav>
					{letters.map(l => (
						<Letter href={`#${l === '#' ? 'hash' : l.toLowerCase()}`} className="h6" active={currentLetter === l}>{l}</Letter>
					))}
				</Nav>
			</NavWrap>
			<NavMobile currentLetter={currentLetter} letters={letters}/>
			<Groups>
				{letters.map(l => (
					<BrandGroup 
						letter={l} 
						brands={l !== '#' ?
							brands.filter(b => b.title[0].toLowerCase() === l.toLowerCase()) :
							brands.filter(b => !letters.slice(0,-1).map(l => l.toLowerCase()).includes(b.title[0].toLowerCase()))
						} 
						setCurrentLetter={setCurrentLetter}
					/>
				))}
			</Groups>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`

const Groups = styled.div`
	margin-bottom: 8rem;
	${mobile}{
		margin-bottom: 5rem;
	}
`

const Header = styled(Section)`
	padding: 50px 0;
	${mobile}{
		padding: 26px 0;
	}
	h2 {
		grid-column: span 9;
		${mobile}{
			grid-column: span 7;
		}
	}
`

const NavWrap = styled(Section)`
	position: sticky;
	top: 50px;
	z-index: 2;
	background: var(--white);
	${mobile}{
		display: none;
	}
`

const Nav = styled.div`
	grid-column: span 12;
	border-top: 1px solid var(--black);
	border-bottom: 1px solid var(--black);
	display: flex;
`

const Letter = styled.a`
	width: 100%;
	display: block;
	border-bottom: ${props => props.active ? '1px solid var(--black)' : '1px solid transparent'};
	padding: 10px 0;
	text-align: center;
`
const NavMobile = styled(AZNavMobile)`
	
`
const RefineButtonWrap = styled.div`
	grid-column: span 3;
	display: flex;
	align-items: flex-end;
	margin-bottom: 23px;
	${mobile}{
		grid-column: span 5;
	}
`

const RefineButton = styled(Button)`
	display: flex;
	align-items: center;
	margin-left: auto;
	svg {
		width: 30px;
		margin-right: 15px;
	}
	${mobile}{
		margin-top: 4px;
	}
`

const RefineButtonText = styled.div`
	background: var(--yellow);
	padding: 5px 12px 7px;
	font-weight: 700;
`

AZ.propTypes = {
	className: PropTypes.string
}

export const query = graphql`
  {
		allSanityBrand(sort: {order: ASC, fields: title}) {
			brands: nodes {
				...brandThumb
			}
		}
	}
`

export default AZ