import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import useEmblaCarousel from 'embla-carousel-react'
import Section from '~components/Section'


const AZNavMobile = ({ className, currentLetter, letters }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, skipSnaps: true, align: 'start' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const currentLetterRef = useRef(currentLetter)

  useEffect(() => {
    currentLetterRef.current = currentLetter
  }, [currentLetter])

  const scrollToIndex = useCallback(
    (index) => {
      if (emblaApi) {
				if (index === 0) {
					emblaApi.scrollTo(0, { animate: true, toIndex: 0, toScroll: emblaApi.scrollProgress() })
				} else {
					emblaApi.scrollTo(index)
				}
      }
    },
    [emblaApi]
  )
	
	useEffect(() => {
    const index = letters.findIndex((letter) => letter === currentLetter)
    if (index !== -1) {
      scrollToIndex(index)
    }
  }, [currentLetter, letters, scrollToIndex])

  return (
    <Wrap className={className}>
      <Nav ref={emblaRef}>
        <Container>
          {letters.map((l) => (
            <Letter href={`#${l === '#' ? 'hash' : l.toLowerCase()}`} className="h6" active={currentLetter === l} key={l}>
              {l}
            </Letter>
          ))}
        </Container>
      </Nav>
    </Wrap>
  );
};

const Wrap = styled(Section)`
	position: sticky;
	top: 50px;
	z-index: 2;
	background: var(--white);
	display: none;
	${mobile}{
		display: block;
	}
`
const Nav = styled.div`
	grid-column: span 12;
	border-top: 1px solid var(--black);
	border-bottom: 1px solid var(--black);
	overflow: hidden;
`
const Container = styled.div`
	display: flex;
`
const Letter = styled.a`
	flex: 0 0 10%;
  min-width: 0;
	display: block;
	border-bottom: ${props => props.active ? '1px solid var(--black)' : '1px solid transparent'};
	padding: 10px 0;
	text-align: center;
`

AZNavMobile.propTypes = {
	className: PropTypes.string,
	currentLetter: PropTypes.number,
}

export default AZNavMobile