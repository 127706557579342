import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import BrandTile from '~components/BrandTile'
import { InView } from 'react-intersection-observer'
import Slider from '~components/NeutralSlider'
import useBreakpoint from '~utils/useBreakpoint'

const BrandGroup = ({ className, brands, letter, setCurrentLetter }) => {

	const handleChange = inView => {
		if(inView){
			setCurrentLetter(letter)
		}
	}

	const { isMobile } = useBreakpoint()

	return (
		<InView as='div' onChange={(inView, entry) => handleChange(inView)} rootMargin='10% 0px -80% 0px'>
			<Wrap className={className} id={letter === '#' ? 'hash' : letter.toLowerCase()}>
				<h5>{letter}</h5>
				{isMobile &&
				<>
				{brands.length ? 
						<MobileSlider>
							{brands.map(brand => <MobileBrandTile content={brand}/>)}
						</MobileSlider> :
							<>
								<h6>No brands</h6>
								<Spacer />
							</>
						}
				</>	
				}
				{!isMobile &&
					<>
						{brands.length ? 
							brands.map(brand => <StyledBrandTile content={brand}/>) :
							<>
								<h6>No brands</h6>
								<Spacer />
							</>
						}
					</>
				}
			</Wrap>
		</InView>
	)
}

const Wrap = styled(Section)`
	padding-top: 6.5rem;
	${mobile}{
		padding-top: 2.5rem;
	}
	> div {
		row-gap: 1.5rem;
	}
	h5 {
		grid-column: span 12;
	}
	h6 {
		grid-column: span 12;
	}
`

const Spacer = styled.div`
	height: 300px;
	${mobile}{
		height: 100px;
	}
`

const StyledBrandTile = styled(BrandTile)`
	grid-column: span 2;
`

const MobileBrandTile = styled(BrandTile)`
	width: 120px;
	margin-right: 1em;
	&:first-child{
		padding-left: 15px;
	}
	&:last-child{
		padding-right: 15px;
	}
`

const MobileSlider = styled(Slider)`
	grid-column: span 12;
	margin: 0 -15px;
`

BrandGroup.propTypes = {
	className: PropTypes.string
}

export default BrandGroup