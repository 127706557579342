import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import '../styles/flickity.css'
import canUseDOM from '../utils/canUseDOM'

const Slider = ({ className, children}) => {

	const [currentSlide, setCurrentSlide] = useState(0)
	const [dragging, setDragging] = useState(false)

	const flkty = useRef()
	const flickityEl = useRef()

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: false,
		cellAlign: 'left',
		freeScroll: true,
		contain: true,
		draggable: true,
	}

	useEffect(() => {
		if (canUseDOM){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
	}, [])

	useEffect(() => {
		if(flkty){
			flkty?.current?.on('dragStart', function(){
				setDragging(true)
			})
			flkty?.current?.on('dragEnd', function(){
				setDragging(false)
			})
		}
	}, [flkty])

	useEffect(() => {
		flkty?.current?.on('change', () => {
      setCurrentSlide(flkty?.current?.selectedIndex)
    })
	}, [flkty])

	const nextSlide = () => {
		flkty?.current?.next()
	}

	const prevSlide = () => {
		flkty?.current?.previous()
	}

	return (
		<Wrap className={className} dragging={dragging}>
			<div ref={flickityEl}>
				{children}
			</div>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
  a, button {
    pointer-events: ${props => props.dragging ? 'none' : 'all'}
  }
`

Slider.propTypes = {
	className: PropTypes.string,
	children: PropTypes.object,
	totalSlides: PropTypes.number,
	onDragging: PropTypes.func,
	centered: PropTypes.bool,
}

export default Slider